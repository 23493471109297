import "./../scss/index.scss";

import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";

import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "animate.css";
import WOW from "wow.js";

// custom
import './custom/iubenda.js';

//#region animate.css triggered on viewport
var wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  mobile: false,
  offset: 0,
});
wow.init();
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});

$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region slider
$(function () {
  $(".slider-team").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    appendArrows: $(".slider-team-arrow-container"),
    prevArrow: ".slider-team-prev-arrow",
    nextArrow: ".slider-team-next-arrow",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  });
  $(".slider-lightbox").slick({
    dots: false,
    arrows: true,
    appendArrows: $(".slider-lightbox-arrow-container"),
    prevArrow: ".slider-lightbox-prev-arrow",
    nextArrow: ".slider-lightbox-next-arrow",
    infinite: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  });
});
//#endregion
//#region gallery
Fancybox.bind('[data-fancybox="gallery__lightgallery"]', {
  // Your custom options
});
//#endregion
//#region read more
$(".link-more").on("click", function () {
  $(".read-more").slideToggle();
});
//#endregion


import jquery from "jquery";
window.jQuery = window.$ = jquery; 

/* IUBENDA */
 
  var loadIubendaBadge = function () {
    var s = document.createElement("script"),
      tag = document.getElementsByTagName("script")[0];
    s.src = "https://cdn.iubenda.com/iubenda.js";
    tag.parentNode.insertBefore(s, tag);
  };

  $(".iubenda-link").each(function (index, element) {
    var iub_link = $(
      '<a href="https://www.iubenda.com/' +
        $(this).data("source") +
        "/" +
        $(this).data("id") +
        ($(this).data("specific") != "" ? "/" : "") +
        $(this).data("specific") +
        '" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ' +
        $(this).data("class") +
        '" title="' +
        $(this).data("text") +
        '">' +
        $(this).data("text") +
        "</a>"
    );
    $(this).append(iub_link);
  });

  loadIubendaBadge();


 